import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SingleCardDivider from "../Utils/SingleCardDivider/SingleCardDivider";
import * as styles from "./Companies.module.scss";
// import Logo1 from "../svgs/logo1.svg";
// import Logo2 from "./svgs/logo2.svg";
// import Logo3 from "./svgs/logo3.svg";
// import Logo4 from "./svgs/logo8.svg";

const DefaultLogos = () => {
  const h = 80;
  return (
    <>
      <Col sm={3}>
        <div className={styles.logoImage}>
          <StaticImage
            height={h}
            src='../../images/logos/manufacturing/meggitt.png'
            alt=''
          />
        </div>
      </Col>
      <Col sm={3}>
        <div className={styles.logoImage}>
          <StaticImage
            className={styles.logoImage}
            height={h}
            src='../../images/logos/construction/procter.png'
            alt=''
          />
        </div>
      </Col>
      <Col sm={3}>
        <div className={styles.logoImage}>
          <StaticImage
            className={styles.logoImage}
            height={h}
            src='../../images/logos/automotive/protect.png'
            alt=''
          />
        </div>
      </Col>
      <Col sm={3}>
        <div className={styles.logoImage}>
          <StaticImage
            className={styles.logoImage}
            height={h}
            src='../../images/logos/manufacturing/abbey.png'
            alt=''
          />
        </div>
      </Col>
    </>
  );
};

function getLogos(logos) {
  let arr = [];

  for (let i = 0; i < logos.allFile.edges.length; i++) {
    if (i === 4) {
      break;
    }
    const image = logos.allFile.edges[i];
    const img = getImage(image.node.childImageSharp);
    const src = image.node.childImageSharp.gatsbyImageData.images.fallback.src;
    const alt = src
      .substring(src.lastIndexOf("/") + 1)
      .replace(/\.[^/.]+$/, "");

    arr.push(
      <Col key={"company-logo-" + i} sm>
        <div className={styles.logo}>
          <GatsbyImage image={img} alt={alt + " logo"} />
        </div>
      </Col>
    );
  }

  return arr;
}

const Companies = ({ logos, title }) => {
  return (
    <section className={styles.root}>
      <SingleCardDivider />
      <Container>
        <h2 className='title'>{title || "Trusted by some big names"}</h2>
        <Row className='align-items-center'>
          {logos ? getLogos(logos) : <DefaultLogos />}
        </Row>
      </Container>
    </section>
  );
};

export default Companies;
