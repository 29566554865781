import React from "react";
import * as styles from "./SingleCardDivider.module.scss";
import Icon from "./svgs/single-card.svg";
import { Controller, Scene } from "react-scrollmagic";

const SingleCardDivider = () => {
  return (
    <Controller>
      <Scene triggerHook={0.9} classToggle='appear' reverse={false}>
        <div className={styles.root}>
          <div className={styles.left} />

          <Icon className={styles.icon} />
          <div className={styles.right} />
        </div>
      </Scene>
    </Controller>
  );
};

export default SingleCardDivider;
