import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Plan from "./svgs/plan.svg";
import Allocate from "./svgs/allocate.svg";
import Track from "./svgs/track.svg";
import Improve from "./svgs/improve.svg";
import * as styles from "./CallToAction.module.scss";
// import { Controller, Scene } from "react-scrollmagic";

const defaultData = [
  {
    title: "Plan",
    text: "Plan tasks ahead on our weekly, monthly, yearly or custom boards",
    icon: <Plan className={styles.icon} />,
  },
  {
    title: "Allocate",
    text: "Allocate tasks however you choose using Row Grouping and custom Column Names",
    icon: <Allocate className={styles.icon} />,
  },
  {
    title: "Track",
    text: "See clearly where a task is in the process and give accurate estimates of completion times",
    icon: <Track className={styles.icon} />,
  },
  {
    title: "Improve",
    text: "Adjust Boards or Card Designs at any time to improve efficiency",
    icon: <Improve className={styles.icon} />,
  },
];

const DefaultTitle = () => {
  return (
    <h2 className='title italic'>
      Proven to <em>increase</em> efficiency...
    </h2>
  );
};

const CallToAction = ({
  data = defaultData,
  sectionTitle = <DefaultTitle />,
}) => {
  return (
    <section className={styles.root}>
      {/* <Controller> */}
      <Container>
        {sectionTitle}
        {/* <Scene
            triggerHook={0.9}
            classToggle='appear'
            reverse={false}
            indicators={true}
          > */}
        <Row>
          {data.map((item, i) => {
            let title = item.title || defaultData[i].title;
            let icon = item.icon || defaultData[i].icon;
            return (
              <Col key={title + i} md={6} lg>
                <div
                  data-sal='fade'
                  data-sal-duration='500'
                  data-sal-delay={i * 300}
                  data-sal-easing='ease'
                >
                  <div className={`${styles.box}`}>
                    {icon}
                    <h3>{title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        {/* </Scene> */}
        <div className={styles.button}>
          <Button
            variant='primary'
            size='lg'
            href='https://app.tcardsonline.co.uk/register'
          >
            Sign up for a free trial now
          </Button>
        </div>
      </Container>
      {/* </Controller> */}
    </section>
  );
};

export default CallToAction;
